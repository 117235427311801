<template>
    <div class="page-main" v-loading="loading">
        <el-form class="edit-select" ref="customerForm" :model="info" :rules="customerRules">
            <div class="edit-select-item">
                <el-form-item prop="orgId" label="所属组织">
                    <organization-select :is-add-all="false" :model="info" allLabel="请选择组织"></organization-select>
                </el-form-item>
                <el-form-item prop="code" label="客户编码">
                    <el-input v-model="info.code" maxLength="32"></el-input>
                </el-form-item>
                <el-form-item prop="type" label="客户类型">
                    <el-select :formatter="zzsnsrlxFormat" v-model="info.type">
                      <el-option v-for="item in zzsnsrlxList"
                                 :key="item.value"
                                 :label="item.label"
                                 :value="item.value">
                      </el-option>
                    </el-select>
                </el-form-item>
            </div>
            <div class="edit-select-item">
                <el-form-item prop="name" label="客户名称">
                    <el-input v-model.trim="info.name" @input="handleInput" maxLength="100"></el-input>
                </el-form-item>
                <el-form-item prop="taxNo" label="客户税号">
                    <el-input v-model.trim="info.taxNo" @input="handleInput" maxLength="20"></el-input>
                </el-form-item>
              <el-form-item label="往来类别" prop="contactType">
                <el-select v-model="info.contactType" placeholder="往来类别">
                  <el-option v-for="type in contactType"
                             :key="type.value"
                             :label="type.label"
                             :value="type.value"/>
                </el-select>
              </el-form-item>
            </div>
          <div class="edit-select-item">
            <el-form-item label="客户标签" prop="label">
              <el-select collapse-tags v-model="labelIds" multiple placeholder="客户标签" @change="labelChange">
                <el-option v-for="type in labelData"
                           :key="type.value"
                           :label="type.label"
                           :value="type.value"/>
              </el-select>
            </el-form-item>
          </div>
          <div class="edit-select-item ">
            <el-col :span="24">
              <el-form-item :cols="3" :rules="[{required: info.customerType == '1' ? true : false,validator: validateAddressTel,trigger: 'blur'}]" label="地址电话"
                            prop="addressTel">
                <!--                    <el-input v-model.trim="info.addressTel" @input="handleInput" maxLength="255"></el-input>-->
                <el-input
                    v-model.trim="info.addressTel"
                    placeholder="请输入内容"
                    type="textarea"
                    @input="handleInput"
                                maxlength="255"
                                rows="1"
                        >
                        </el-input>
                    </el-form-item>
                </el-col>
            </div>
            <div class="edit-select-item ">
                <el-col :span="24">
                    <el-form-item prop="bankAccount" label="开户行及账号"
                                  :rules="[{required: info.customerType == '1' ? true : false,validator: validateBankAccount,trigger: 'blur'}]">
                        <!--                <el-input v-model.trim="info.bankAccount" :col="120" @input="handleInput" maxLength="255"></el-input>-->

                        <el-input
                                type="textarea"
                                placeholder="请输入内容"
                                v-model.trim="info.bankAccount"
                                @input="handleInput"
                                maxlength="255"
                                rows="1"
                        >
                        </el-input>
                    </el-form-item>
                </el-col>
            </div>
            <div class="edit-select-item">
                <el-form-item prop="contact" label="联系人">
                    <el-input v-model.trim="info.contact" @input="handleInput" maxLength="10"></el-input>
                </el-form-item>
                <el-form-item prop="contactTel" label="联系人电话">
                    <el-input v-model.trim="info.contactTel" @input="handleInput" maxLength="20"></el-input>
                </el-form-item>
                <el-form-item prop="email" label="邮箱地址">
                    <el-input v-model.trim="info.email" @input="handleInput" maxLength="150"></el-input>
                </el-form-item>
            </div>
            <div class="edit-select-item">
                <el-col :span="24">
                    <el-form-item prop="remark" label="备注">
                        <el-input
                                type="textarea"
                                placeholder="请输入内容"
                                v-model.trim="info.remark"
                                maxlength="200"
                                show-word-limit
                        >
                        </el-input>
                    </el-form-item>
                </el-col>
            </div>
            <div class="edit-select-item">
            </div>

            <div class="edit-select">
                <div class="edit-select-foot">
                    <el-button class="el-button-ext" @click="handleCancel">取消</el-button>
                    <el-button class="el-button-ext" v-loading="saveBtnLoading" @click="handleSaveCustomer"
                               type="primary">保存
                    </el-button>
                </div>
            </div>
        </el-form>
    </div>
</template>

<script>
import OrganizationSelect from '@/components/widgets/OrganizationSelect'
import {checkUniqueCode, insertCustomer, updateCustomer} from '@/service/customer'
import {getAllLabels} from "@/service/system/label";

export default {
    name: 'CustomerEdit',
    components: {OrganizationSelect},
    props: {
        customer: {
            type: Object,
            default: () => {
            }
        },
        content: Array,
        scene: undefined
    },
    watch: {},
    computed: {
        priceDisabled() {
            var that = this.info
            let price = this.info.price
            if (price == null || price === '') {
                that.priceVariable = '1'
                return true
            } else {
                return false
            }
        }
    },
    data() {
        return {
          labelIds: [],
          labelData: [],
          loading: false,
          info: this.customer,
          zzsnsrlxList: [
            {label: '一般纳税人', value: '1'},
            {label: '小规模纳税人', value: '2'},
            {label: '其他', value: '0'}
          ],
          contactType: [
            {label: '客户', value: '客户'},
            {label: '供应商', value: '供应商'},
                { label: '两者皆是', value: '两者皆是' }
            ],
            customerRules: {
                orgId: [{required: true, message: '请选择组织！'}],
                name: [
                    {required: true, message: '请输入客户名称!'},
                    {min: 1, max: 100, message: '长度为 1~100 个字符!', trigger: 'blur'}
                ],
                code: [
                    {min: 1, max: 32, message: '长度为 1~32 个字符!', trigger: 'blur'},
                    {'validator': this.checkUnique, trigger: 'blur'}
                ],
                customerType: [{required: true, message: '请选择客户类型！'}],
                type: [{required: true, message: '请选择客户类型！'}],
                contactType: [{required: true, message: '请选择往来类别！'}],
                taxNo: [
                    {required: true, message: '请输入客户税号!'},
                    {min: 1, max: 20, message: '长度为 1~20 个字符!', trigger: 'blur'}
                ],
                email: [
                    {required: false, message: '请输入邮箱地址!'},
                    {min: 0, max: 150, message: '长度最大150 个字符!', trigger: 'blur'}
                ],
                contact: [
                    {required: false, message: '请输入联系人!'},
                    {min: 0, max: 10, message: '长度最大10 个字符!', trigger: 'blur'}
                ],
                contactTel: [
                    {required: false, message: '请输入联系人电话!'},
                    {min: 0, max: 20, message: '长度最大20 个字符!', trigger: 'blur'}
                ],
                remark: [
                    {required: false, message: '请输入备注'},
                    {min: 0, max: 200, message: '长度最大200 个字符!', trigger: 'blur'}
                ]
            },
            saveBtnLoading: false
        }
    },
    created() {
      this.getDicts('zzsnsrlx').then(res => {
        this.zzsnsrlxList = res.data
      })
      this.initLabels()
      this.labelIds = this.customer.labelIds
    },
    methods: {
      labelChange(e) {
        if (e.length > 5) {
          this.$message.warning('最多选中5个标签')
          this.labelIds.splice(-1)
        }
      },
      async initLabels() {
        const {success, data} = await getAllLabels();
        if (success && data) {
          let arr = []
          for (let d of data) {
            arr.push({
              label: d.name,
              value: d.id
            })
            this.labelData = arr
          }
        }
      },
      validateAddressTel(rule, value, callback) {
        if (this.info.customerType != '1') {
          return
        }
        if (value == '' || value == undefined || value == null) {
          callback(new Error('请输入地址、电话!'));
        } else if (value.length > 255) {
          callback(new Error('长度为 1~255 个字符!'));
        } else {
          callback();
            }
        },
        validateBankAccount(rule, value, callback) {
            if (this.info.customerType != '1') {
                return
            }
            if (value == '' || value == undefined || value == null) {
                callback(new Error('请输入地址、电话!'));
            } else if (value.length > 255) {
                callback(new Error('长度为 1~255 个字符!'));
            } else {
                callback();
            }
        },
        handleInput(e) {
            this.$forceUpdate()
        },
        // 点击取消的回调
        handleCancel(status) {
            this.$emit('handleCancelEditDialog', status)
        },
        // 重置表单
        resetForm() {
            this.$refs.customerForm.resetFields()
        },
        // 客户编码唯一性校验
        checkUnique(rule, value, callback) {
            if (!value) return callback(new Error('客户编号不允许为空!'))
            checkUniqueCode({
                uniqueCode: this.info.code,
                id: this.info.id ? this.info.id : 0
            }).then(res => {
                if (res.success) {
                    if (res.data) {
                        callback(new Error('已存在相同编号客户'))
                        return
                    }
                }
                callback()
            })
        },
        // 点击提交 -> 参数校验
        handleSaveCustomer() {
            if (this.customer.type == '1') {
                this.submitCustomer()
            } else {
                // 校验部分字段
                let fieldsToValidate = ['orgId', 'name', 'code', 'type', 'taxNo']
                Promise.all(
                    fieldsToValidate.map((field) => {
                        return new Promise((resolve, reject) => {
                            this.$refs['customerForm'].validateField(field, (errorMessage) => {
                                resolve(errorMessage);
                            });
                        });
                    })
                ).then((errorMessages) => {
                    let valid = errorMessages.every((errorMessage) => {
                        return errorMessage == '';
                    });
                    if (valid) this.submitCustomer()
                });
            }
        },
        zzsnsrlxFormat(value) {
            return this.getDictLabel(this.zzsnsrlxList, value.type)
        },
        // 提交客户信息
        async submitCustomer() {

          // loading保存按钮阻止反复提交
          this.saveBtnLoading = true
          // 添加客户
          if (this.labelIds.length > 0) {
            this.info.labelIds = this.labelIds
          }
          const {success} = this.info.id > 0 ? await updateCustomer(this.info) : await insertCustomer(this.info)
          const message = this.info.id > 0 ? '修改' : '新增'
          this.saveBtnLoading = false
          if (success) {
            this.toast(`${message}客户信息成功`, 'success', () => this.handleCancel('success'))
            if (this.scene == 'Billing') {
              this.$emit('edit-finish', this.info)
            }
          } else {
            this.toast(`${message}客户信息失败`, 'error')
            }
        }
    }
}
</script>

<style scoped lang="scss">
::v-deep .el-form-item__label {
  padding: 0;
  line-height: 24px;
}

::v-deep .el-form-item__error {
  top: 64px;
  padding-top: 2px;
}

.edit-select {
  display: flex;
  flex-direction: column;

  .edit-select-item {
    margin-bottom: 24px;
    padding-bottom: 32px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .edit-select-item1 {
    margin-bottom: 24px;
    padding-bottom: 32px;
    height: 60px;
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .edit-select-foot {
    display: flex;
    height: 60px;
    width: 100%;
    justify-content: flex-end;
  }
}

.page-main {
  padding: 0 24px;

  .tax-class-code {
    height: 32px;
    width: 170px;
    display: flex;
    padding: 0 15px;
    border-radius: 4px;
    align-content: center;
    border: 1px solid #DCDFE6;
    justify-content: space-between;

    i {
      color: #3D94FF;
      line-height: 32px;
      margin-right: -4px;
    }
  }
}
</style>
